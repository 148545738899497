<template>
    <main>
        <page-hero site="Ranking"/>
        <section class="top-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 text-center">
                        <h3 class="section-title">TOP {{ currentOption }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 group-pills">
                        <div v-for="option in options" :key="option" :class="[currentOption === option ? 'active' : '']" class="pill"
                             @click="setCurrentOption(option)">{{ option }}
                        </div>
                    </div>
                </div>
                <div class="row podium">
                    <div class="col-lg-4  order-lg-0 silver order-1">
                        <div class="card">
                            <div class="card-body">
                                <div class="medal">
                                    <div class="quiz-medal">
                                        <div class="quiz-medal__circle quiz-medal__circle--silver">
                        <span>
                          2
                        </span>
                                        </div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
                                    </div>
                                </div>
                                <div class="info">
                                    <b-skeleton-wrapper :loading="loading">
                                        <template #loading>
                                            <h3 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h3>
                                            <h6 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h6>
                                        </template>

                                        <h5><client :client="getTopClient(1)" center withAvatar trim/></h5>
                                        <h6>{{ getTopData(1) }}</h6>

                                    </b-skeleton-wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-lg-1 gold order-0">
                        <div class="card">
                            <div class="card-body">
                                <div class="medal">
                                    <div class="quiz-medal">
                                        <div class="quiz-medal__circle quiz-medal__circle--gold">
                        <span>
                          1
                        </span>
                                        </div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
                                    </div>
                                </div>
                                <div class="info">
                                    <b-skeleton-wrapper :loading="loading">
                                        <template #loading>
                                            <h3 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h3>
                                            <h6 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h6>
                                        </template>

                                        <h5><client :client="getTopClient(0)" center withAvatar trim/></h5>
                                        <h6>{{ getTopData(0) }}</h6>

                                    </b-skeleton-wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-lg-2 bronze order-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="medal">
                                    <div class="quiz-medal">
                                        <div class="quiz-medal__circle quiz-medal__circle--bronze">
                        <span>
                          3
                        </span>
                                        </div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
                                        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
                                    </div>
                                </div>
                                <div class="info">
                                    <b-skeleton-wrapper :loading="loading">
                                        <template #loading>
                                            <h3 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h3>
                                            <h6 class="d-flex justify-content-center">
                                                <b-skeleton width="80%"/>
                                            </h6>
                                        </template>

                                        <h5><client :client="getTopClient(2)" center withAvatar trim/></h5>
                                        <h6>{{ getTopData(2) }}</h6>

                                    </b-skeleton-wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row clients-table">
                    <div v-for="(client, num) in currentClients" :key="num" :class="[num < 3 ? 'hidden' : '']"
                         class="col-xl-12 single-client d-flex flex-wrap">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 py-2">
                            <div class="place text-left">
                                # {{ num + 1 }}
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-5 col-md-5 col-sm-12 py-2">
                            <div class="nick text-center">
                                <b-skeleton-wrapper :loading="loading">
                                    <template #loading>
                                        <span><b-skeleton width="8em"/></span>
                                    </template>

                                    <client :client="client.client" center withAvatar/>

                                </b-skeleton-wrapper>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-12 py-2">
                            <div class="info text-right">
                                <b-skeleton-wrapper :loading="loading">
                                    <template #loading>
                                        <span><b-skeleton width="8em"/></span>
                                    </template>

                                    <span>{{ getClientData(client) }}</span>

                                </b-skeleton-wrapper>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </main>
</template>

<script>
import MainService from "../services/main.service";
const humanizeDuration = require("humanize-duration");

export default {
    name: "Top",
    data() {
        return {
            loading: false,
            options: ['Ilość połączeń', 'Spędzony czas', 'Najdłuższe połączenie', 'Poziomy', 'Czas AFK', 'Tygodnia'],
            currentOption: 'Ilość połączeń',
            optionsMap: {
                'Ilość połączeń': 'connections',
                'Spędzony czas': 'connectedTimeSpent',
                'Najdłuższe połączenie': 'connectedTimeRecord',
                'Poziomy': 'levels',
                'Czas AFK': 'idleTimeRecord',
                'Tygodnia': 'weekTimeSpent'
            },
            currentClients: []
        }
    },
    methods: {
        async setCurrentOption(newOption) {
            if (this.loading)
                return;

            this.loading = true;
            this.currentOption = newOption;

            MainService.getTop(this.optionsMap[newOption]).then(
                response => {
                    this.currentClients = response.data;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error)
                }
            );
        },
        getTopClient(num) {
            return (this.currentClients[num] ? this.currentClients[num].client : {dbid: '---', nick: '---'});
        },
        getTopData(num) {
            return (this.currentClients[num] ? this.convertData(this.currentClients[num][this.optionsMap[this.currentOption]]) : '---');
        },
        getClientData(client) {
            return this.convertData(client[this.optionsMap[this.currentOption]]);
        },
        convertData(details) {
            if(this.optionsMap[this.currentOption] === 'connectedTimeSpent' ||
               this.optionsMap[this.currentOption] === 'connectedTimeRecord' ||
               this.optionsMap[this.currentOption] === 'idleTimeRecord' ||
               this.optionsMap[this.currentOption] === 'weekTimeSpent')
                return humanizeDuration(details * 1000, { language: "pl", round: true, delimiter: " " });
            else
                return details;
        }
    },
    mounted() {
       this.setCurrentOption(this.options[0]);
    }
}
</script>

<style scoped>

</style>